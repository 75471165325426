import React from "react"
import { Button, Row, Col, Container } from "reactstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import { Link } from "gatsby"
import SEO from "../components/seo"

export default class Template extends React.Component {
  render() {
    const {
      data: {
        allMarkdownRemark: { edges },
      },
    } = this.props

    return (
      <Layout>
        <SEO title="Services" description="" />
        <Container className="mt-50 mb-5 pt-5">
          <h2 className="text-center">Services</h2>
          <Row className="m-0">
            {edges.map(
              (
                {
                  node: {
                    id,
                    frontmatter: {
                      categoryName,
                      path,
                      image: {
                        childImageSharp: { fluid },
                      },
                    },
                  },
                },
                index
              ) => (
                <Col className="mt-5 d-flex justify-content-center" key={index}>
                  <div className="s-card-wrapper">
                    <div className="media">
                      <Image fluid={fluid} style={{ width: 400 }} />
                    </div>
                    <Link to={path}>
                      <div className="body d-flex flex-column align-items-center justify-content-center px-2">
                        <h6 className="font-weight-bolder text-center text-secondary">
                          {categoryName}
                        </h6>
                      </div>
                    </Link>
                    <div className="overlay justify-content-center align-items-center">
                      <Link to={path}>
                        <Button color="danger" className="mt-2 py-1 btn-s">
                          Explore
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              )
            )}
          </Row>
        </Container>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/markdown-pages/*.md" } }
      sort: { order: ASC, fields: [frontmatter___path] }
    ) {
      edges {
        node {
          id
          frontmatter {
            categoryID
            categoryName
            path
            image {
              childImageSharp {
                fluid(maxWidth: 350, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
